<template>
  <BillingPlanSelectionCard
    :title="tt('billing.modal.individualConditions')"
    :subtitle="tt('billing.modal.forCargoOwners')"
    :planCode="EBillingPlanCode.cargoOwner"
    :actionText="tt('billing.submitApplication')"
    withoutPrice
    style="width: 100%;"
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ `${tt('billing.modal.creatingTenders')};` }}</li>
      <li>{{ `${tt('billing.modal.distributionOrdersByExecutors')};` }}</li>
      <li>{{ `${tt('billing.modal.controlOfExecutionAndDelivery')};` }}</li>
      <li>{{ tt('billing.modal.organizationOfTheEnterpriseCourtyardManagementProcess') }}</li>
    </template>
  </BillingPlanSelectionCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';

export default defineComponent({
  name: 'BillingCardsForCargoOwner',
  components: { BillingPlanSelectionCard },
  emits: ['change'],
  setup() {
    return {
      EBillingPlanCode,

      tt,
    };
  },
});
</script>
