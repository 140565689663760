import { computed, reactive } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { _UnwrapAll } from 'pinia';

import tt from '@/i18n/utils/translateText';
import { TCustomStore } from '@/store/types';
import {
  ECommonSchemaItemKeys,
  EShipperRunsKeys,
} from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import isCurrentUserAdmin from '@/contexts/accountingContext/domain/isCurrentUserAdmin';
import isCurrentUserSupport from '@/contexts/accountingContext/domain/isCurrentUserSupport';
import { EFoldersSection } from '@/stores/folders/constants';
import { useView as useViewSiderContent } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/TmsSiderContent/application/useView';
import { TEntityOrderFolder } from '@/contexts/orderingContext/domain/types';
import { TFoldersStore } from '@/stores/folders/types';

import { TCommonSchemaItem } from '../domains/types';

export const useView = ({
  store,
  route,
  foldersStore,
}: {
  store: TCustomStore,
  route: RouteLocationNormalizedLoaded,
  /** Необходимо передать хранилище аргументом из потребителя,
   * потому что Pinia не успевает инициализироваться, если обращаться к ней из этого юза */
  foldersStore: _UnwrapAll<TFoldersStore>,
}) => {
  // Массив ключей, используемых в сайдбаре. Массив формирует порядок вывода элементов меню
  const orderedMenuItemKeys = [
    ECommonSchemaItemKeys.transportationCalculator,
    ECommonSchemaItemKeys.orders,
    ECommonSchemaItemKeys.executorsLookupsFeed,
    ECommonSchemaItemKeys.digitalQueues,
    ECommonSchemaItemKeys.shipments,
    ECommonSchemaItemKeys.directions,
    ECommonSchemaItemKeys.matrices,
    ECommonSchemaItemKeys.references,
    ECommonSchemaItemKeys.quotas,
    ECommonSchemaItemKeys.executionRequests,
    ECommonSchemaItemKeys.selfBilling,
    ECommonSchemaItemKeys.tenders,
    ECommonSchemaItemKeys.shipperRuns,
    ECommonSchemaItemKeys.employees,
    ECommonSchemaItemKeys.contractors,
    ECommonSchemaItemKeys.operationalDocuments,
    ECommonSchemaItemKeys.vehicles,
    ECommonSchemaItemKeys.partnerships,
    ECommonSchemaItemKeys.partnershipResources,
    ECommonSchemaItemKeys.lookupStrategies,
    ECommonSchemaItemKeys.priceLists,
    ECommonSchemaItemKeys.reportTemplates,
    ECommonSchemaItemKeys.extraServices,
    ECommonSchemaItemKeys.approvals,
  ];

  const state = reactive({
    selectedKeys: [] as string[],
    openKeys: [] as string[],
  });

  const getOrdersPath = () => {
    const firstFolderId = foldersStore.foldersState[EFoldersSection.orderList].folders?.[0]?.id;

    return firstFolderId
      ? `/orders/only_orders?folderId=${firstFolderId}`
      : '/orders/only_orders';
  };

  const matricesFolders = computed(() => foldersStore.foldersState[EFoldersSection.matricesList]?.folders);
  const customReferencesFolders = computed(() => foldersStore.foldersState[EFoldersSection.referencesList]?.folders);

  const { ordersSchemaItem, commonSchemaItems } = useViewSiderContent(matricesFolders);

  // Массив со всеми элементами для сайдбара
  const siderMenuSchema = computed(() => {
    const dynamicSchemaItems: TCommonSchemaItem[] = [];

    dynamicSchemaItems.push({
      ...ordersSchemaItem,
      path: getOrdersPath(),
    });

    // Объединяем статичную схему и схему заказов с динамическим роутом
    return [...commonSchemaItems.value, ...dynamicSchemaItems];
  });

  const currentTenant = computed(() => store.state.tenants.currentTenant);
  const isAdminRoute = computed(() => route.path.startsWith('/admin'));
  const isAdmin = computed(() => isCurrentUserSupport() || isCurrentUserAdmin());

  const isSidebarVisible = computed(() => !!store.state.tenants.currentTenant);
  const folderOpenKeys = computed(() => store.state.app.folderOpenKeys);

  const folderId = computed(() => route.query.folderId as string);
  const orderFolders = computed((): TEntityOrderFolder[] => {
    const { folders } = foldersStore.foldersState[EFoldersSection.orderList];
    if (!folders.length) return [];
    return (folders as TEntityOrderFolder[]).map((folder: TEntityOrderFolder) => ({
      id: folder.id,
      name: tt(`sider.menu.children.${folder.id}`),
    }));
  });

  const currentRunsCount = computed(() => store.state.contractorInfo.currentRunsCount);
  const plannedRunsCount = computed(() => store.state.contractorInfo.plannedRunsCount);
  const finishedRunsCount = computed(() => store.state.contractorInfo.finishedRunsCount);
  const incidentsCount = computed(() => store.state.contractorInfo.incidentsCount);

  const getShipperRunsCount = (runKey: string | EShipperRunsKeys) => {
    switch (runKey) {
      case EShipperRunsKeys.current:
        return currentRunsCount.value;
      case EShipperRunsKeys.planned:
        return plannedRunsCount.value;
      case EShipperRunsKeys.finished:
        return finishedRunsCount.value;
      case EShipperRunsKeys.incidents:
        return incidentsCount.value || null;
      default:
        return null;
    }
  };

  return {
    isAdmin,
    isAdminRoute,
    isSidebarVisible,
    state,
    folderOpenKeys,
    orderFolders,
    currentTenant,
    folderId,
    orderedMenuItemKeys,
    matricesFolders,
    customReferencesFolders,
    siderMenuSchema,

    getShipperRunsCount,
  };
};
