import { getActualAuthToken } from '@/api/sendApiRequest';
import logger from '@/logger';
import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { TCableSubscription } from '@/websocket/types';

import {
  connectConsumer,
  closeConnection,
  getConsumerInstance,
  getCurrentTenantId,
  getUrl,
} from './index';
import { addTaskToStack } from './tasksStack';

export const openWsConnection = () => {
  getConsumerInstance();
};

// TODO: удалить после выпила фф refactoringWebsocketController
const setUrlToConsumer = async () => {
  const consumer = await getConsumerInstance();
  const tokens = await getActualAuthToken() as any;
  const tenantId = getCurrentTenantId();

  if (!tokens?.accessToken || !tenantId) {
    const message = '[setUrlToConsumer] accessToken or tenantId is undefined. Logout.';

    logger.warn(message);
    throw new Error(message);
  }

  consumer._url = getUrl({
    tenantId,
    token: tokens?.accessToken || '',
  });
};

export const disconnectConsumer = async () => {
  const consumer = await getConsumerInstance();
  const isRefactoringWebsocketControllerAvailable = checkFeatureIsEnabled(EExperimentalFeatures.refactoringWebsocketController);

  if (consumer) {
    if (isRefactoringWebsocketControllerAvailable) {
      // отписываемся от всех каналов перед дисконнектом
      consumer.subscriptions.subscriptions
        .forEach((subscription: TCableSubscription) => {
          subscription.unsubscribe();
        });
    }

    consumer.disconnect();
  }
};

export const reconnectWithNewUrl = async () => {
  const isRefactoringWebsocketControllerAvailable = checkFeatureIsEnabled(EExperimentalFeatures.refactoringWebsocketController);
  const removeTask = addTaskToStack();

  try {
    await disconnectConsumer();
    if (isRefactoringWebsocketControllerAvailable) {
      // ничего делать не надо
    } else {
      await setUrlToConsumer();
    }
    await connectConsumer();

    removeTask();
  } catch {
    await closeConnection();
    logger.log('[reconnectWithNewUrl] setUrlToConsumer().catch');
    store.dispatch('app/logout');
  }
};
