<template>
  <div
    v-for="card in infoForCarrierPlanCards"
    :key="card.planCode"
  >
    <BillingPlanSelectionCard
      :title="card.title"
      :subtitle="card.subtitle"
      :planCode="card.planCode"
      :price="card.price"
      :forExtended="card.isExpanding"
      :hasInfoAboutTrialPeriod="card.hasInfoAboutTrialPeriod"
      :isActivePlan="card.isActivePlan"
      @change="$emit('change', $event)"
    >
      <template #description>
        <li v-for="(description, index) in card.description" :key="index">
          {{ description }}
        </li>
      </template>
    </BillingPlanSelectionCard>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import {
  TBillingAvailablePlansResponse,
  TInfoForPlanCards,
} from '@/contexts/billingContext/domain/types';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';
import { useView } from '../../application/useView';
import { getPlanPrice } from '../../domain/getPlanPrice';

export default defineComponent({
  name: 'BillingCardsForCarrier',
  components: { BillingPlanSelectionCard },
  props: {
    availablePlans: {
      type: Array as PropType<TBillingAvailablePlansResponse>,
      default: () => [],
    },
  },
  emits: ['change'],
  setup(props) {
    const {
      hasInfoAboutTrialPeriod,
      currentActiveSubscription,
    } = useView();

    const infoForCarrierPlanCards = ref<TInfoForPlanCards[]>([
      {
        title: tt('billing.planCode.start'),
        subtitle: tt('billing.modal.forParticipateTenders'),
        price: getPlanPrice({
          planCode: EBillingPlanCode.carrierStart,
          availablePlans: props.availablePlans,
          currentActiveSubscription: currentActiveSubscription.value,
        }),
        planCode: EBillingPlanCode.carrierStart,
        isExpanding: false,
        hasInfoAboutTrialPeriod: false,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.carrierStart,
        description: [
          `${tt('billing.modal.twoManagersForOrganization')};`,
          tt('billing.modal.accessToTenders'),
        ],
      },
      {
        title: tt('billing.planCode.basic'),
        subtitle: tt('billing.modal.forSmallAndMediumOrganizations'),
        price: getPlanPrice({
          planCode: EBillingPlanCode.carrierBasic,
          availablePlans: props.availablePlans,
          currentActiveSubscription: currentActiveSubscription.value,
        }),
        planCode: EBillingPlanCode.carrierBasic,
        hasInfoAboutTrialPeriod: hasInfoAboutTrialPeriod.value,
        isExpanding: false,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.carrierBasic,
        description: [
          `${tt('billing.modal.twoManagersForOrganization')};`,
          tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndTenTools'),
        ],
      },
      {
        title: tt('billing.planCode.extended'),
        subtitle: tt('billing.modal.forLargeCarriers'),
        price: getPlanPrice({
          planCode: EBillingPlanCode.carrierExtended,
          availablePlans: props.availablePlans,
          currentActiveSubscription: currentActiveSubscription.value,
        }),
        planCode: EBillingPlanCode.carrierExtended,
        hasInfoAboutTrialPeriod: hasInfoAboutTrialPeriod.value,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.carrierExtended,
        isExpanding: true,
        description: [
          `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
          `${tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate')};`,
          tt('billing.modal.transportationPriceCalculator'),
        ],
      },
    ].filter((card: TInfoForPlanCards) => props.availablePlans?.some((plan) => plan.planCode === card.planCode)
      || card.isActivePlan),
    );

    return {
      infoForCarrierPlanCards,
      EBillingPlanCode,
      currentActiveSubscription,

      tt,
    };
  },
});
</script>
