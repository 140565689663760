import {
  ComputedRef,
  computed,
} from 'vue';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { _UnwrapAll } from 'pinia';

import { getFolders } from '@/contexts/orderingContext/services';
import { TCustomStore } from '@/store/types';
import {
  TNewFolder,
  TNewFolderResponse,
} from '@/types';
import { getMatricesColumnsConfig } from '@/contexts/rateManagementContext/services';
import { EFoldersSection } from '@/stores/folders/constants';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { getContractorInfo } from '@/contexts/contractorsContext/services';
import { TReachedResponse } from '@/api/types';
import { TContractorInfo } from '@/components/layouts/domain/types';
import isTenantVerified from '@/domains/isTenantVerified';
import { canBrowseMatricesByRole } from '@/domains/permissions/role/matrices';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasShipperWorkplaceSubscription,
} from '@/domains/permissions/subscription';
import { getCustomReferencesColumnsConfig } from '@/contexts/referencesContext/services';
import { canBrowseOrdersSectionByRole } from '@/domains/permissions/role/orders';
import { canBrowseRunsByRole } from '@/domains/permissions/role/runs';
import canBrowseCustomReferencesByRole from '@/domains/permissions/role/customReferences/canBrowseCustomReferencesByRole';
import { TCustomizationStore } from '@/stores/customization/types';
import { showErrorNotification } from '@/utils';
import { EViewSettingsWSEvents, useTenantSettingsWsChannel } from '@/composables/application/useTenantSettingsWsChannel';
import { EOrderFolderSlug, ROUTE_NAME } from '@/contexts/orderingContext/domain/constants';
import { useFoldersStore } from '@/stores/folders';
import { TCustomReference } from '@/contexts/referencesContext/domain/types';

type TUseFetchDataArguments = {
  folderId: ComputedRef<string>,
  store: TCustomStore,
  router: Router,
  route: RouteLocationNormalizedLoaded,
  customizationStore: _UnwrapAll<TCustomizationStore>,
};

export const useFetchData = (
  {
    folderId,
    store,
    router,
    route,
    customizationStore,
  }: TUseFetchDataArguments,
) => {
  const foldersStore = useFoldersStore();
  const isCustomReferencesAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.customReferences));
  const isOrderMaxColumnsAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.orderMaxColumns));

  const fetchFolderColumnsConfig = (folderId: string, section: EFoldersSection) => {
    switch (section) {
      case EFoldersSection.matricesList:
        return getMatricesColumnsConfig(folderId)
          .then((response) => {
            if (!response.data) return;
            foldersStore.setColumnsSettings(section, response.data);
          })
          .catch(showErrorNotification);
      case EFoldersSection.referencesList:
        return getCustomReferencesColumnsConfig(folderId).then((response) => {
          if (!response.data) return;
          foldersStore.setColumnsSettings(section, response.data);
        });
      default: {
        return Promise.reject();
      }
    }
  };

  const openOrderFolder = () => {
    const currentFolderId = foldersStore.foldersState[EFoldersSection.orderList]?.currentFolderId;
    const firstFolderId = foldersStore.foldersState[EFoldersSection.orderList].folders[0].id;
    const folderIdInQueryParams = route.query.folderId as EOrderFolderSlug;

    if (!currentFolderId || currentFolderId !== folderIdInQueryParams) {
      const orderFolderSlug: EOrderFolderSlug = folderIdInQueryParams || firstFolderId;
      router.replace({
        query: {
          ...route.query,
          folderId: orderFolderSlug,
        },
      });
      foldersStore.setCurrentFolderId(EFoldersSection.orderList, orderFolderSlug);
      foldersStore.loadOrderFolderInfo(orderFolderSlug);
    } else {
      // Если папка выбрана - получаем данные папки
      foldersStore.loadOrderFolderInfo(currentFolderId);
    }
  };

  const fetchNewOrderFolders = () => {
    if (hasCargoOwnerWorkplaceSubscription() && canBrowseOrdersSectionByRole()) {
      getFolders()
        .then((response: TReachedResponse<TNewFolderResponse>) => {
          if (!response.data) return;
          foldersStore.setFolders(EFoldersSection.orderList, response.data.map((folder: TNewFolder) => ({
            id: folder.slug,
            name: folder.title,
          })));

          // Если в момент загрузки папок для заказов открыт раздел "Заказы" - открываем нужную папку
          const isOpenOrdersRoute = router.currentRoute.value.name === ROUTE_NAME.ordersListOnlyOrders;

          if (isOpenOrdersRoute) {
            openOrderFolder();
          }
        })
        .catch(() => {});
    }
  };

  const currentAppSettings = computed(() => store.state.app.appSettings);

  const updateTableViewSettings = (payload: { maxColumns: number }) => {
    if (!isOrderMaxColumnsAvailable.value) return;

    // сбрасываем папки заказов и настройки их таблиц
    foldersStore.$reset();

    // сетим новое значение во viewSettingsMaxColumns из payload эвента
    if (payload.maxColumns) {
      store.commit('app/SET_APP_SETTINGS', {
        experimentalFeatures: currentAppSettings.value?.experimentalFeatures || {},
        licenseSettings: currentAppSettings.value?.licenseSettings || null,
        settings: {
          sberBusinessEnabled: currentAppSettings.value?.settings?.sberBusinessEnabled || false,
          viewSettingsMaxColumns: payload.maxColumns,
          displayPricesSettings: currentAppSettings.value?.settings?.displayPricesSettings || true,
        },
      });
    }
    // если у пользователя есть доступ к разделу папок - запрашиваем папки заказов
    if (hasCargoOwnerWorkplaceSubscription() && canBrowseOrdersSectionByRole()) {
      fetchNewOrderFolders();
    }
  };

  /** Обновляем настройки колонок таблицы на эвент по WS */
  useTenantSettingsWsChannel({ [EViewSettingsWSEvents.viewSettingMaxColumnsUpdated]: updateTableViewSettings });

  const fetchMatrices = () => {
    // Запрашиваем матрицы только для тенанта с кастомизацией и при наличии доступа к разделу
    if (getIsTenantWithCustomization() && canBrowseMatricesByRole()) {
      const isOpenMatricesFolder = route.path === '/matrices' && folderId.value;

      customizationStore.loadMatrices()
        .then(() => {
          foldersStore.setFolders(EFoldersSection.matricesList, customizationStore.matrices.map((matrix) => ({
            id: matrix.id,
            name: matrix.name,
          })));

          store.dispatch('filter/setSubsections', {
            section: 'matricesListView',
            subSections: customizationStore.matrices.map((matrix) => matrix.id),
          });

          if (isOpenMatricesFolder) {
            foldersStore.setCurrentFolderId(EFoldersSection.matricesList, folderId.value);
            fetchFolderColumnsConfig(folderId.value, EFoldersSection.matricesList);
          }
        })
        .catch(() => {});
    }
  };

  const fetchCustomReferences = () => {
    // Запрашиваем кастомные справочники только для тенанта с кастомизацией и при наличии доступа к разделу
    // TODO: заменить canBrowseMatricesByRole на доступ к справочникам, когда для них появится ролевая модель
    if (getIsTenantWithCustomization() && canBrowseCustomReferencesByRole() && isCustomReferencesAvailable.value) {
      const isOpenCustomReferencesFolder = route.path === '/references' && folderId.value;

      customizationStore.loadCustomReferences()
        .then(() => {
          foldersStore.setFolders(EFoldersSection.referencesList, customizationStore.customReferences.map(
            (customReference: TCustomReference) => ({
              id: customReference.id,
              name: customReference.name,
            })));

          store.dispatch('filter/setSubsections', {
            section: 'referencesListView',
            subSections: customizationStore.customReferences.map(
              (customReference: TCustomReference) => customReference.id),
          });

          if (isOpenCustomReferencesFolder) {
            foldersStore.setCurrentFolderId(EFoldersSection.referencesList, folderId.value);
            fetchFolderColumnsConfig(folderId.value, EFoldersSection.referencesList);
          }
        })
        .catch(() => {});
    }
  };

  const fetchRunsCountInfo = () => {
    // Если тенант не верифицирован или нет доступа, то инфо не запрашиваем
    if (!isTenantVerified()
      || !(hasCargoOwnerWorkplaceSubscription() || hasShipperWorkplaceSubscription())
      || !canBrowseRunsByRole()
    ) return;

    getContractorInfo()
      .then((response: TReachedResponse<TContractorInfo>) => {
        if (!response.data) return;

        store.dispatch('contractorInfo/setCurrentRunsCount', { count: response.data.onExecutionRunsCount });
        store.dispatch('contractorInfo/setPlannedRunsCount', { count: response.data.plannedRunsCount });
        store.dispatch('contractorInfo/setFinishedRunsCount', { count: response.data.completedRunsCount });
        store.dispatch('contractorInfo/setIncidentsCount', { count: response.data.incidentsCount });
        store.dispatch('contractorInfo/setCanSeeRunIncidents', { canSeeRunIncidents: response.data.canSeeRunIncidents });
      })
      .catch(() => {});
  };

  const fetchFolders = () => {
    fetchNewOrderFolders();
    fetchMatrices();
    fetchCustomReferences();
  };

  return {
    fetchFolderColumnsConfig,
    fetchFolders,
    fetchRunsCountInfo,
  };
};
