import { ROUTE_TYPE } from '@/constants';
import store from '@/store';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

const checkRouteType = (route) => {
  const isRefactoringWebsocketControllerAvailable = checkFeatureIsEnabled(
    EExperimentalFeatures.refactoringWebsocketController,
  );

  if (isRefactoringWebsocketControllerAvailable) {
    const isRouteUnauthenticated = route.meta.type === ROUTE_TYPE.unauthenticated;
    const isAuthenticated = store.getters['app/isAuthenticated'];
    if (isRouteUnauthenticated && isAuthenticated) {
      redirectToInitialRoute();
    }
  }

  return Promise.resolve();
};

export default checkRouteType;
