import { createConsumer } from '@rails/actioncable';
import debounce from 'lodash.debounce';

import { getActualAuthToken } from '@/api/sendApiRequest';
import loggerInstance, { turnOffLogger } from '@/logger';
import { parseJson } from '@/utils';
import store from '@/store';
import { ONE_SECOND } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import type {
  TCable,
  TCableSubscription,
  TConsumerInstance,
  TWsMessage,
} from './types';
import { getTasksStackPromise } from './tasksStack';
import { MESSAGE_REASON, MESSAGE_TYPE } from './constants';

let consumerInstance: TConsumerInstance = null;

const logger = import.meta.env.VITE_APP_WEBSOCKET_LOGGER_ENABLED === 'true'
  ? loggerInstance
  : turnOffLogger(loggerInstance);

const baseWsHost = import.meta.env.VITE_APP_WS_HOST || null;

export const getCurrentTenantId = (): string => store.state.tenants?.currentTenant?.id || '';
const getFieldName = (tenantId?: string) => (!tenantId ? 'tenantId' : 'token');

export const getUrl = ({ tenantId, token }: { tenantId: string, token: string }): string => {
  if (!baseWsHost) {
    logger.warn('[getUrl] baseWsHost is empty! Looks like you did not provide value for VITE_APP_WS_HOST env variable.');
    return '';
  }

  if (!tenantId || !token) {
    logger.warn(`[handleMessageEvent reconnect] ${getFieldName(tenantId)} is undefined.`);
    return '';
  }

  return `${baseWsHost}/cable?token=${token}&tenantId=${tenantId}`;
};

/**
 * Больше информации
 * docs/development/implementation-details/websockets.md
 */

export const connectConsumer = async () => {
  const consumer = await getConsumerInstance();

  if (consumer) {
    consumer.connect();
  }
};

export const connectConsumerWithDebounce = debounce(connectConsumer, ONE_SECOND * 3);

export const closeConnection = async () => {
  const consumer = await getConsumerInstance();

  if (consumer) {
    consumer.connection.close();
  }
};

export const handleMessageEvent = (consumer: TConsumerInstance, eventParsed: TWsMessage) => {
  if (!consumer) return Promise.reject();

  const isRefactoringWebsocketControllerAvailable = checkFeatureIsEnabled(EExperimentalFeatures.refactoringWebsocketController);

  const {
    type,
    reason,
    identifier,
  } = eventParsed;

  // если бэк говорит, что мы не авторизованы, то обновляем токен и снова подключаемся
  if (type === MESSAGE_TYPE.disconnect && reason === MESSAGE_REASON.unauthorized) {
    logger.warn('Connection is unauthorized. Disconnect.');

    closeConnection();
    if (isRefactoringWebsocketControllerAvailable) return Promise.reject(eventParsed);

    // После выпила фф isRefactorWebSocketAvailable удалить весь код ниже до конца ближайшего if
    getActualAuthToken()
      .then((tokens: any) => {
        if (!consumer) return;

        const accessToken = tokens?.accessToken || '';
        const tenantId = getCurrentTenantId();

        if (!accessToken || !tenantId) {
          logger.warn(`[handleMessageEvent reconnect] ${getFieldName(tenantId)} is undefined.`);
          return;
        }

        consumer._url = getUrl({
          tenantId,
          token: accessToken,
        });

        connectConsumerWithDebounce();
      })
      .catch(() => {});

    return Promise.reject(eventParsed);
  }

  // Если в подписке отказано, то отписываемся от канала
  if (type === MESSAGE_TYPE.rejectSubscription) {
    const { channel } = identifier || {};
    const rejectedChannel = consumer.subscriptions.subscriptions.find((s: any) => {
      const parsedIdentifier = parseJson(s.identifier);

      return parsedIdentifier.channel === channel;
    });

    if (rejectedChannel) {
      logger.warn(`Connection to channel "${channel}" is rejected. Unsubscribe from channel.`);

      rejectedChannel.unsubscribe();
    }
  }

  return Promise.resolve();
};

/**
 * [getConsumerInstance] Через singleton создаётся единственный потребитель на всё приложение
 * Здесь же происходит подписка на входящие сообщения
 */
export const getConsumerInstance = async (): Promise<TCable> => {
  const isRefactoringWebsocketControllerAvailable = checkFeatureIsEnabled(EExperimentalFeatures.refactoringWebsocketController);

  try {
    const tokens = await getActualAuthToken() as any;
    const tenantId = getCurrentTenantId();
    const url = getUrl({
      tenantId,
      token: tokens?.accessToken || '',
    });

    if (!consumerInstance && url) {
      consumerInstance = createConsumer(url) as TCable;

      const initialMessageEvent = consumerInstance.connection.events.message;

      consumerInstance.connection.events.message = (event: any) => {
        if (!consumerInstance) return;

        const eventParsed = parseJson(event?.data) as TWsMessage;

        handleMessageEvent(consumerInstance, eventParsed)
          .then(() => {
            initialMessageEvent.call(consumerInstance?.connection, event);
          })
          .catch((error) => {
            logger.warn('[handleMessageEvent] error: ', error);
          });
      };

      consumerInstance.connect();
    } else if (consumerInstance && isRefactoringWebsocketControllerAvailable) {
      consumerInstance._url = url;
    }
  } catch (error) {
    return Promise.reject(error);
  }

  return consumerInstance as TCable;
};

export const createChannel = ({
  channel,
  onInitialize = () => {},
  onConnect = () => {},
  onMessage = () => {},
  onClose = () => {},
  onError = () => {},
}: any) => {
  if (!channel) {
    logger.warn('Trying to create channel with empty channelName. Abort.');
    return Promise.reject();
  }
  const initialized = () => {
    onInitialize();
    logger.log(`Channel "${channel?.channel || channel}" successfully initialized.`);
  };

  const connected = () => {
    onConnect();
    logger.log(`Channel "${channel?.channel || channel}" successfully connected.`);
  };

  const received = (data: any) => {
    const parsed = parseJson(data);
    logger.log(`Message from channel "${channel?.channel || channel}": `, parsed);
    onMessage(parsed);
  };

  const disconnected = () => {
    onClose();
    logger.log(`Channel "${channel?.channel || channel}" successfully disconnected.`);
  };
  const rejected = onError;

  return getConsumerInstance()
    .then(async (consumer: TCable) => {
      /**
       * await getTasksStackPromise – ждём, пока выполнятся все внутренние функции контроллера,
       * и только потом выполняем код подписки на канал.
       * Внутренняя функция – например, функция reconnectWithNewUrl для подключения с новым tenantId
       */
      await getTasksStackPromise();

      const consumerChannel = consumer.subscriptions.create(channel, {
        initialized,
        connected,
        received,
        disconnected,
        rejected,
      }) as unknown as TCableSubscription;

      const unsubscribe = () => {
        consumerChannel.unsubscribe();
      };

      // Если мы пытаемся подписаться на канал, который уже лежит в подписках, то начинают лететь бесконечные подписки в консоли
      // Чтобы этого избежать, нужно отменить старую и добавить новую. Подробнее https://github.com/rails/rails/issues/44652
      consumer.subscriptions.confirmSubscription(consumerChannel.identifier);

      const initialErrorEvent = consumer.connection.events.error;
      /* eslint-disable-next-line no-param-reassign */
      consumer.connection.events.error = (err: any) => {
        logger.log(channel, 'WebSocket error: ', err);
        onError(err);
        initialErrorEvent.call(consumer.connection, err);
      };

      return unsubscribe;
    })
    .catch((error) => {
      logger.warn('[createChannel] Error occurred while trying to create consumer. Error: ', error.message);
    });
};
